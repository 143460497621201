import _trim from "lodash-es/trim";
import React from 'react';
import { Layout } from '@/common/components/layout';
import { ScrollRestore } from '@/common/components/scroll-restore';
import { SEO } from '@/common/components/seo';
import { useAppInfo, useUri } from '@/common/hooks';
import { usePostPreviewRedirect } from '@/common/hooks/post-preview-redirect-hook';
import { PostContent } from '@/post/componenets/post-content';
import { jsx as ___EmotionJSX } from "@emotion/core";
var query = "831649542";

var Post = function Post(_ref) {
  var _data$cms, _data$cms$post, _data$cms$post$data, _data$cms2, _data$cms2$post, _data$cms2$post$data, _attributes$seo, _data$cms$post2, _data$cms$post2$data, _seo$social$facebook$, _seo$social, _seo$social$facebook, _seo$social$twitter$t, _seo$social2, _seo$social2$twitter, _data$cms$post3, _data$cms$post3$data, _seo$social3, _seo$social3$facebook, _seo$social3$facebook2, _seo$social3$facebook3, _seo$social3$facebook4, _seo$social3$facebook5, _seo$social3$facebook6, _seo$social4, _seo$social4$twitter, _seo$social4$twitter$, _seo$social4$twitter$2, _seo$social4$twitter$3, _seo$social4$twitter$4, _seo$social4$twitter$5, _ref2, _seo$social$facebook$2, _seo$social5, _seo$social5$facebook, _ref3, _seo$social$twitter$d, _seo$social6, _seo$social6$twitter, _data$cms3, _data$cms3$post, _data$cms3$post$data, _data$cms4, _data$cms4$post, _data$cms4$post$data;

  var data = _ref.data;

  var _useAppInfo = useAppInfo(),
      defaultMetaDescription = _useAppInfo.defaultMetaDescription;

  var _useUri = useUri(),
      uriHelper = _useUri.uriHelper;

  usePostPreviewRedirect(data === null || data === void 0 ? void 0 : (_data$cms = data.cms) === null || _data$cms === void 0 ? void 0 : (_data$cms$post = _data$cms.post) === null || _data$cms$post === void 0 ? void 0 : (_data$cms$post$data = _data$cms$post.data) === null || _data$cms$post$data === void 0 ? void 0 : _data$cms$post$data.id);
  var attributes = data === null || data === void 0 ? void 0 : (_data$cms2 = data.cms) === null || _data$cms2 === void 0 ? void 0 : (_data$cms2$post = _data$cms2.post) === null || _data$cms2$post === void 0 ? void 0 : (_data$cms2$post$data = _data$cms2$post.data) === null || _data$cms2$post$data === void 0 ? void 0 : _data$cms2$post$data.attributes;
  var pageTitle = (attributes === null || attributes === void 0 ? void 0 : (_attributes$seo = attributes.seo) === null || _attributes$seo === void 0 ? void 0 : _attributes$seo.title) || (attributes === null || attributes === void 0 ? void 0 : attributes.title);
  var seo = (_data$cms$post2 = data.cms.post) === null || _data$cms$post2 === void 0 ? void 0 : (_data$cms$post2$data = _data$cms$post2.data) === null || _data$cms$post2$data === void 0 ? void 0 : _data$cms$post2$data.attributes.seo;

  var ogTitle = _trim((_seo$social$facebook$ = seo === null || seo === void 0 ? void 0 : (_seo$social = seo.social) === null || _seo$social === void 0 ? void 0 : (_seo$social$facebook = _seo$social.facebook) === null || _seo$social$facebook === void 0 ? void 0 : _seo$social$facebook.title) !== null && _seo$social$facebook$ !== void 0 ? _seo$social$facebook$ : pageTitle);

  var twitterTitle = _trim((_seo$social$twitter$t = seo === null || seo === void 0 ? void 0 : (_seo$social2 = seo.social) === null || _seo$social2 === void 0 ? void 0 : (_seo$social2$twitter = _seo$social2.twitter) === null || _seo$social2$twitter === void 0 ? void 0 : _seo$social2$twitter.title) !== null && _seo$social$twitter$t !== void 0 ? _seo$social$twitter$t : pageTitle);

  var meta = [{
    property: 'og:url',
    content: uriHelper.ensureAbsoluteUrl(uriHelper.getCmsPageUrl((_data$cms$post3 = data.cms.post) === null || _data$cms$post3 === void 0 ? void 0 : (_data$cms$post3$data = _data$cms$post3.data) === null || _data$cms$post3$data === void 0 ? void 0 : _data$cms$post3$data.attributes.uri))
  }, {
    property: 'og:image',
    content: CONFIG.appUrl + ((seo === null || seo === void 0 ? void 0 : (_seo$social3 = seo.social) === null || _seo$social3 === void 0 ? void 0 : (_seo$social3$facebook = _seo$social3.facebook) === null || _seo$social3$facebook === void 0 ? void 0 : (_seo$social3$facebook2 = _seo$social3$facebook.image) === null || _seo$social3$facebook2 === void 0 ? void 0 : (_seo$social3$facebook3 = _seo$social3$facebook2.asset) === null || _seo$social3$facebook3 === void 0 ? void 0 : (_seo$social3$facebook4 = _seo$social3$facebook3.localImageFile) === null || _seo$social3$facebook4 === void 0 ? void 0 : (_seo$social3$facebook5 = _seo$social3$facebook4.childImageSharp) === null || _seo$social3$facebook5 === void 0 ? void 0 : (_seo$social3$facebook6 = _seo$social3$facebook5.resize) === null || _seo$social3$facebook6 === void 0 ? void 0 : _seo$social3$facebook6.src) || '')
  }, {
    property: 'twitter:image',
    content: CONFIG.appUrl + ((seo === null || seo === void 0 ? void 0 : (_seo$social4 = seo.social) === null || _seo$social4 === void 0 ? void 0 : (_seo$social4$twitter = _seo$social4.twitter) === null || _seo$social4$twitter === void 0 ? void 0 : (_seo$social4$twitter$ = _seo$social4$twitter.image) === null || _seo$social4$twitter$ === void 0 ? void 0 : (_seo$social4$twitter$2 = _seo$social4$twitter$.asset) === null || _seo$social4$twitter$2 === void 0 ? void 0 : (_seo$social4$twitter$3 = _seo$social4$twitter$2.localImageFile) === null || _seo$social4$twitter$3 === void 0 ? void 0 : (_seo$social4$twitter$4 = _seo$social4$twitter$3.childImageSharp) === null || _seo$social4$twitter$4 === void 0 ? void 0 : (_seo$social4$twitter$5 = _seo$social4$twitter$4.resize) === null || _seo$social4$twitter$5 === void 0 ? void 0 : _seo$social4$twitter$5.src) || '')
  }, {
    property: 'og:title',
    content: ogTitle
  }, {
    property: 'og:description',
    content: (_ref2 = (_seo$social$facebook$2 = seo === null || seo === void 0 ? void 0 : (_seo$social5 = seo.social) === null || _seo$social5 === void 0 ? void 0 : (_seo$social5$facebook = _seo$social5.facebook) === null || _seo$social5$facebook === void 0 ? void 0 : _seo$social5$facebook.description) !== null && _seo$social$facebook$2 !== void 0 ? _seo$social$facebook$2 : seo === null || seo === void 0 ? void 0 : seo.description) !== null && _ref2 !== void 0 ? _ref2 : defaultMetaDescription
  }, {
    name: 'twitter:title',
    content: twitterTitle
  }, {
    name: 'twitter:description',
    content: (_ref3 = (_seo$social$twitter$d = seo === null || seo === void 0 ? void 0 : (_seo$social6 = seo.social) === null || _seo$social6 === void 0 ? void 0 : (_seo$social6$twitter = _seo$social6.twitter) === null || _seo$social6$twitter === void 0 ? void 0 : _seo$social6$twitter.description) !== null && _seo$social$twitter$d !== void 0 ? _seo$social$twitter$d : seo === null || seo === void 0 ? void 0 : seo.description) !== null && _ref3 !== void 0 ? _ref3 : defaultMetaDescription
  }];
  return ___EmotionJSX(Layout, {
    key: data === null || data === void 0 ? void 0 : (_data$cms3 = data.cms) === null || _data$cms3 === void 0 ? void 0 : (_data$cms3$post = _data$cms3.post) === null || _data$cms3$post === void 0 ? void 0 : (_data$cms3$post$data = _data$cms3$post.data) === null || _data$cms3$post$data === void 0 ? void 0 : _data$cms3$post$data.id
  }, ___EmotionJSX(SEO, {
    title: pageTitle !== null && pageTitle !== void 0 ? pageTitle : '',
    meta: meta,
    description: (seo === null || seo === void 0 ? void 0 : seo.description) || defaultMetaDescription
  }), ___EmotionJSX(ScrollRestore, {
    uri: uriHelper.getCmsPageUrl((_data$cms4 = data.cms) === null || _data$cms4 === void 0 ? void 0 : (_data$cms4$post = _data$cms4.post) === null || _data$cms4$post === void 0 ? void 0 : (_data$cms4$post$data = _data$cms4$post.data) === null || _data$cms4$post$data === void 0 ? void 0 : _data$cms4$post$data.attributes.uri)
  }), ___EmotionJSX(PostContent, {
    data: data
  }));
};

Post.displayName = "Post";
export { Post as default, query };